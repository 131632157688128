.reviewMapBox{
    border: 1px solid #ccc;
    margin-top: -2px;
}

.reviewMainTitle{
    margin-top: 10px;
    padding: 0 5px;
    font-weight: bold;
    font-size: 20px;
}

.reviewMainRating{
    position: relative;
    margin-top: -5px;
    padding: 5px;
    font-size: 16px;
}

.reviewMainCounting1{
    position: absolute;
    top:10px;
    left: 146px;
    font-size: 14px;
    font-weight: bold;
    font-family: Arial;
    color: #888;
    cursor:pointer;
}

.reviewMainList {
  display: flex;
  justify-content: center;
  align-items: top;
  /* border: 1px solid black; */
  border-radius: 10px;
}

.reviewMainListBox {
  /* float: left; */
  /* border: 1px solid black; */
  width: 110px;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #888;
}

.reviewMainListTitle {
  color: black;
}

.reviewMainInfo{
    margin-top: 10px;
    padding: 5px;
    /* border: 1px solid black; */
    font-size: 14px;
}

.reviewMainInfoLink{
    text-decoration: none;
}

.reviewMainRatingScore{
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    font-size: 18px;
}

.reviewMainCounting{
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
    margin-top: 3px;
    font-size: 14px;
    font-weight: bold;
    font-family: Arial;
    color: #888;
    cursor:pointer;
}

.reviewMainCounting:hover{
    opacity: 0.8;
}

.reviewButtonBox{
    padding: 10px 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: top;
}

.reviewButtonBox1{
    width: 170px;
    height: 40px;
    font-size: 14px;
    border: 2px solid #BDBDBD;
    border-radius: 10px;
    background-color: white;
}

.reviewList-icon{
    margin-bottom: 3px;
}


.reviewListBack{
    position: absolute;
    top:0px;
    left: 15px;
}

.reviewListTitle{
    position: relative;
    margin-top: 10px;
    padding: 0 5px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.reviewListRating{
    position: relative;
    /* left: -15px; */
}



