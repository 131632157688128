.userlist-container{
    min-height: calc(100vh - 25vh);
}

.userlist-title {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1em 0;
}

.user-mypagelist {
    padding: 10px;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.member-list-container {
    display: flex;
    margin: 0 auto;
    padding: 20px;
    overflow-x: auto;
    max-width: calc(100% - 10px);
}

.member-table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    text-align: center;
    align-items: center;
}

.admin-table-title {
    background-color: #f5f5f5;
}

.member-checkbox,
.member-id,
.member-name,
.member-email,
.member-join-date,
.member-memo {
    border: 1px solid #ddd;
    padding: 5px;
    min-width: 1em;
    white-space: nowrap;
    text-align: center;
    align-items: center;
    font-size: 13px;
}

.member-memo{
    width: 50%;
}

.member-memo > .member-memo-textarea {
    width: 90%;
}

.member-checkbox {
    width: 1em;
}

.member-id {
    font-weight: bold;
}

.member-row:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

input[type='checkbox'] {
    cursor: pointer;
}

.member-list-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1em 0;
}

.member-list-actions button {
    width: 5em;
    height: auto;
    font-size: 1rem;
    padding: 3px 0.5rem;
    margin: 1em;
    border-radius: 0.5rem;
    border: none;
    color: #fff;
    background-color: #0077ff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}