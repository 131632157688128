* {
  margin: 0px;
  padding: 0px;
}

/* RegionLocationSelect.js */

.wrapper1 {
  margin: 0 10px;
  padding: 10px;
  overflow: auto;
}

.local_title {
  margin: 20px;
  clear: both;
  font-size: 26px;
  font-weight: 600;
}

.content {
  display: inline-block;
  margin: 10px 3.5%;
  padding: 15px;
  border: 1px solid #99b8ff;
  font-size: 12px;
  border-radius: 15px;
  clear: both;
  width: 43%;
  height: 100%;
  box-shadow: 3px 3px 3px #b0b0b0;
}

.content:hover {
  color: #ffffff;
  background-color: #99b8ff;
  cursor: pointer;
  transform: all ease 2s 0s;
}

.locationTag {
  display: block;
  margin: 0 auto;
  width: 80px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #537fe7;
  color: #ffffff;
  background-color: #537fe7;
}

.content_title {
  margin-top: 10px;
}
