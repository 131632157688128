.writeForm {
    width: 95%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.writeTitle {
    border: 0px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 0;
    white-space: normal;
    word-wrap:break-word;
}

.writeFormContent {
    margin: -20px 0 0 0;
}

.contentForm {
    height: 300px;
    font-size: 0.8rem;
    border-radius: 0;
}

.uploadInput {
    display: none;
}

.uploadImgDiv {
    margin: 30px auto 0 15px;
    width: 240px;
    
}

.listGroupItem {
    width: 240px;
    text-align: center;
    margin: -1px 10px 0 5px;
    text-align: center;
    border-top-width: 2px;
}

#listGroupItemId {
    border-top-width: 1px;
}

.uploadImg {
    margin: 5px auto 0 0px;
    width: 100%;
}

.uploadBtn {
    color: rgb(99, 94, 94);
    border-radius: 0.1rem;
    cursor: pointer;
    float: right;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0px 65px 0 0;
    font-size: 0.7rem;
    width: 20px;
    height: 20px;
    opacity: 0.2;
}

.uploadBtnImg {
    width: 50px;
    height: 50px;
}

.imgDeleteBtn {
    border: none;
    background-color: white;
}

.imgDeleteBtn:hover {
    color: gray;
}

.imgTitle {
    margin: 8px 0 0px 0;
    text-align: center;
    font-weight: bold;
    width: 200px;
    white-space: normal;
    word-wrap:break-word;
}

.imgDeleteBtnDiv {
    text-align: center;
}

.submit {
    background-color: #2d88ff;
    color: white;
    /* padding: 0.5rem 1rem; */
    border-radius: 0.25rem;
    cursor: pointer;
    align-items: center;
    margin-right: 60px;
    margin-left: auto;
}

.boardListBtn {
    margin-left: 10px;
    margin-right: 10px;
    
}

.btns {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 20px;
}

.trachCanIcon {
    color: rgba(25, 25, 25, 0.7);
}

.writeContentTextP {
    text-align: right;
    margin: -10px 5px 0px 0;
    font-size: 0.8rem;
}

/* BoardUpdate -----------------------------------*/

.h2_BoardUpdate {
    margin: 20px 0 0px 20px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #537FE7;
}