* {
  margin: 0px;
  padding: 0px;
}

.free_title {
  margin: 20px;
  clear: both;
  font-size: 26px;
  font-weight: 600;
}

.freeboardwrite_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 30px auto;
}
