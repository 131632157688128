#mapwrap{position:relative;}
.mapcategory, .mapcategory *{margin:0;padding:0;color:#000;}   
.mapcategory {
    position:absolute;
    overflow:hidden;
    top:0;
    width: 100%;
    height: auto;
    padding: 2px;
    z-index:1; 
    font-family:'Malgun Gothic','맑은 고딕',sans-serif;
    font-size:14px;text-align:center;
    background-color:#fff;
    font-weight: bold;
}
.mapcategory .menu_selected {background:lightgray;border:1px solid lightgray; border-radius: 5px; transition: 0.8s;} 
.mapcategory li{list-style:none; float: left; width:59px;height:55px;padding-top:5px;cursor:pointer;} 
.mapcategory .ico_comm {display:block;margin:0 auto 2px;width:22px;height:26px;background:url('../../public/mapimg/maker.png') no-repeat;} 
.mapcategory .ico_hospital {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/hospital1.png') no-repeat; background-size: cover;}  
.mapcategory .ico_pharmacy {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/pharmacy1.png') no-repeat; background-size: cover;}   
.mapcategory .ico_culturalCenter {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/culturalCenter1.png') no-repeat;background-size: cover;} 
.mapcategory .ico_artmuseum {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/artmuseum1.png') no-repeat;background-size: cover;} 
.mapcategory .ico_beauty {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/beauty1.png') no-repeat;background-size: cover;} 
.mapcategory .ico_museum {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/museum1.png') no-repeat;background-size: cover;} 
.mapcategory li:hover{
    color: #999;
    opacity: 0.9;
}

.mapcategory2, .mapcategory2 *{margin:0;padding:0;color:#000;}   
.mapcategory2 {
    position:absolute;
    overflow:hidden;
    top:59px;
    width:100%;
    height:auto;
    padding: 2px;
    z-index:1;
    border-top-width: 0px;
    font-family:'Malgun Gothic','맑은 고딕',sans-serif;
    font-size:14px;text-align:center;
    background-color:#fff;
    font-weight: bold;
}
.mapcategory2 .menu_selected {background:lightgray;border:1px solid lightgray; border-radius: 5px; transition: 0.8s;} 
.mapcategory2 li{list-style:none; float: left; width:59px;height:55px;padding-top:5px;cursor:pointer;} 
.mapcategory2 .ico_comm {display:block;margin:0 auto 2px;width:22px;height:26px;background:url('../../public/mapimg/maker.png') no-repeat;} 
.mapcategory2 .ico_petGoods {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/petGoods1.png') no-repeat;background-size: cover;}  
.mapcategory2 .ico_restaurant {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/restaurant1.png') no-repeat;background-size: cover;}   
.mapcategory2 .ico_travel {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/travel1.png') no-repeat;background-size: cover;} 
.mapcategory2 .ico_management {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/management1.png') no-repeat;background-size: cover;} 
.mapcategory2 .ico_cafe {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/cafe1.png') no-repeat;background-size: cover;} 
.mapcategory2 .ico_pension {display:block;margin:0 auto 2px;width:29px;height:29px;background:url('../../public/mapimg/pension1.png') no-repeat;background-size: cover;} 
.mapcategory2 li:hover{
    color: #999;
    opacity: 0.9;
}

.mapbox, .mapbox *{margin:0;padding:0;color:#000;}   
.mapbox {position:absolute;overflow:hidden;bottom:10px;right:10px;width:auto;height:auto;z-index:1;border:1px solid #537FE7;font-family:'Malgun Gothic','맑은 고딕',sans-serif;font-size:12px;text-align:center;background-color:#fff;font-weight: bold; border-radius: 5px;}
.mapbox li{list-style:none; float: left; width:50px;height:50px;padding-top:5px;cursor:pointer; } 
.mapbox .ico_comm {display:block;margin:0 auto 2px;width:22px;height:26px;background:url('../../public/mapimg/mapicon.png') no-repeat;} 
.mapbox:hover{
    background-color: #eee;
}
.mapwrapinfo {position: absolute;left: 0;bottom: 30px;width: 230px;height: 122px;margin-left: -114px;text-align: left;overflow: hidden;font-size: 12px;font-family: 'Malgun Gothic', dotum, '돋움', sans-serif;line-height: 1.5;}
.mapwrapinfo * {padding: 0;margin: 0;}
.mapwrapinfo .mapinfo {width: 286px;height: 110px;border-radius: 5px;border-bottom: 2px solid #ccc;border-right: 1px solid #ccc;overflow: hidden;background: #fff;}
.mapwrapinfo .mapinfo:nth-child(1) {border: 0;box-shadow: 0px 1px 2px #888;}
.mapinfo .maptitle {padding: 3px 0 0 10px;width: 230px;height: 30px;background: #eee;border-bottom: 1px solid #ddd;font-size: 16px;font-weight: bold;}
.mapinfo .mapclose {position: absolute;top: 7px;right: 10px;color: #888;width: 17px;height: 17px;background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/overlay_close.png');}
/* .mapinfo .mapclose:hover {cursor: pointer;} */
.mapwrapinfo :hover {cursor: pointer;}
.mapinfo .mapbody {position: relative; padding: 3px; top:5px; left: 5px; overflow: hidden;}
/* .mapinfo .desc {position: relative;margin: 13px 0 0 90px;height: 75px;} */
.desc .infoellipsis {overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.desc .jibun {font-size: 11px;color: #888;margin-top: -2px;}
/* .mapinfo .img {position: absolute;top: 6px;left: 5px;width: 73px;height: 71px;border: 1px solid #ddd;color: #888;overflow: hidden;} */
.mapinfo:after {content: '';position: absolute;margin-left: -12px;left: 50%;bottom: 0;width: 22px;height: 12px;background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')}
.mapinfo .maplink {color: #5085BB; text-decoration: none;}
.maplink:hover{
    text-decoration: underline;
}
.infoellipsis {width: 200px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

