.commentDiv {
    display: block;
    height: auto;
    margin-bottom: 10px;
    border-top: 1px solid #537fe738;
    overflow:auto;
}

/* .signUpLogoDiv {
    height: 30px;
    width: 40px;
    float: left;
    margin: 2px -10px -5px 2px;
} */

.commentProfileDiv {
    display: inline-block;
    margin: -10px -10px -5px 2px;
}

.commentContentDiv {
    display: inline-block;
    margin: 10px 0 10px 0px;
    text-align: start;
    width: 253px;
}

.commentProfileImg {
    border-radius: 50px;
    margin: -40px 10px -10px 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.commentNickname {
    display: block;
    float: left;
    margin: 0 0 0 30px;
    font-weight: bold;
    cursor: pointer;
}

.commentNickname:hover {
    color: #537FE7;
}

.comment {
    display: block;
    margin: 0 0 0 30px;
    float: left;
    text-align:initial;
    
}

.writeReply {
    display: block;
    color: #537FE7;
    margin: 10px 0 0 29px;
    float: left;
    cursor: pointer;
}

.commentDate {
    position: fixed;
    display: inline;
    float: right;
    margin: 0px 5px 0 20px;
    font-size: 0.7rem;
    color: gray;
}

/* 답글 작성 */
.writeReplyTextarea {
    width: 250px;
    margin: 5px 0 0 25px;
}

.writeReplyBtn {
    width: 62px;
    font-size: 0.6rem;
    margin-right: 100px;
}

/* replyDiv -------------------------------------*/

.replyDiv {
    margin: 0 0 0 50px;
}

.replyCommentDiv{
    display: inline-block;
    margin: 10px 0 10px 0px;
    text-align: start;
    width: 100px;
}

.replyContentDiv {
    display: inline-block;
    margin: 10px 0 10px 0px;
    text-align: start;
    width: 190px;
}