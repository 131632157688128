.reportmodal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.reportmodal .reportModalCloseBtn {
  outline: none;
  cursor: pointer;
  border: 0;
}
.reportmodal > .reportModalSectionDiv {
  position: absolute;
  top: 20%;
  width: 90%;
  max-width: 375px;
  max-height: 260px;
  margin: 0 auto 0 18px;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: reportmodal-show 0.3s;
  overflow: hidden;
  transform: translate(0%, -10%);
}
.reportmodal > .reportModalSectionDiv > .reportModalHeaderDiv {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 16px 64px 16px 16px;
  background-color: #fc00002d;
  font-weight: 700;
  text-align: center;
}

.reportModalHeaderText {
  text-align: center;
  margin-left: 40px;
  color: rgb(25, 25, 25);
}

.reportmodal
  > .reportModalSectionDiv
  > .reportModalHeaderDiv
  .reportModalCloseBtn {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.reportmodal > .reportModalSectionDiv > .reportModalMainDiv {
  padding: 16px;
  border-bottom: 1px solid #fc00002d;
  border-top: 1px solid #dee2e6;
  text-align: center;
}
.reportmodal > .reportModalSectionDiv > .reportModalFooterDiv {
  padding: 12px 16px;
  text-align: right;
}
.reportmodal
  > .reportModalSectionDiv
  > .reportModalFooterDiv
  .reportModalCloseBtn {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}
.reportmodal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: reportmodal-bg-show 0.3s;
}
@keyframes reportmodal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes reportmodal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reportReasonWrite {
  margin: 20px 0 0 0;
  font-size: 0.7rem;
  white-space: normal;
  word-wrap: break-word;
}

/* media query ------------------------------------------------------------------------------------------ */
@media screen and (min-width: 800px) {
  .reportmodal > .reportModalSectionDiv {
    position: absolute;
    top: 40%;
    left: 35%;
    width: 90%;
    max-width: 375px;
    max-height: 260px;
    margin: 0 auto 0 18px;
    border-radius: 0.3rem;
    background-color: #fff;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: reportmodal-show 0.3s;
    overflow: hidden;
    transform: translate(0%, -10%);
  }
}
