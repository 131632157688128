footer {
    position: relative;
    /* full-bg를 기준으로 배치 */
    bottom: 0;
    left: 0;
    width : 100%;
    background-color: #f5f5f5;
    padding: 1rem;
}

.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logo img {
    width: 100px;
    margin-bottom: 1rem;
}

.socialIcons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.footerContainer .myFooterText {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 12px;
}