.wrap {
    height: calc(100% - 369px);
}

/* full-bg 클래스를 가진 section 요소를 화면 전체에 꽉 차게 배치 */
.admin-full-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../../public/img/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

/* 배경 커버 색상*/
.main-bg {
    position: relative;
    left: 0;
    height: 100%;
    background-color: #f0f0f08b;
}

/* 배경 웹사이트 설명 공간*/
.admin-bg-left {
    position: absolute;
    left: 0;
    height: 100%;
    width: 50%;
    display: none;
}

/* 로고 컨테이너 */
.logo {
    padding-top: 13%;
    padding-left: 20%;
    margin-bottom: 2rem;
}

.logo img {
    width: 11em;
    height: auto;
}

/* 바탕 왼쪽  */
.main-left {
    float: right;
    align-items: center;
    margin-right: 30%;
}

.mainContent {
    display: block;
    text-align: right;
    margin-right: 1rem;
}

.mainContent h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #0c0c0c8b;
    margin: 2em 0 5px 0;
}

.mainImg {
    display: block;
    text-align: center;
}


.mainImg img {
    width: 3em;
    height: auto;
}

/* =========================================*/

/* 관리자 main */
.admin-main {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80%;
    height: 100%;
    min-height: 100vh;
    background-color: #ffffff;
}

/* 본문 내용을 담는 section 요소를 Header와 Footer 사이에 배치 */
main section:last-of-type {
    margin-top: 1px;
    margin-bottom: 5px;
}

section {
    min-height: calc(100vh - 27vh);
}

/* 미디어쿼리 */
@media screen and (max-width: 870px) {

    /* main-bg 안의 요소들 숨기기 */
    .bg-left {
        display: none;
    }

    /* main 요소 중앙에 배치 */
    main {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 360px;
    }

    .admin-main{
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
    }
}