.report-container{
    min-height: calc(100vh - 25vh);
}

.user-mypagereport {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userlist-title {
    display: flex;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 0;
}

.hr-line {
    align-items: center;
}

.report-category {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.report-review-name{
    position: absolute;
    right: 10px;
    top:15px;
    white-space: nowrap;
    font-weight: bold;
}

.report-category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    margin: 0 1em;
}

/* ====================================== */

.admin-user-report-title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1em;
}

.admin-user-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    border-top: 1px solid #ddd;
}

/* ====================================== */

/* ============== 리뷰 추가 ============== */

.report-select-container{
    position: relative;
    margin: 0 auto;
    width: 95%;
}

.report-select{
  position: absolute;
  right: 0px;
  bottom: 10px;
  width: 130px;
}

.report-review-content{
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 3px;
}

/* ============== 리뷰 추가 ============== */

.report-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-user-report-table {
    width: 95%;
    height: auto;
    border-collapse: collapse;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.admin-user-report-item-title,
.admin-user-report-item-date {
    border: 1px solid #ddd;
    padding: 5px;
    min-width: 2em;
    white-space: nowrap;
    text-align: center;
    align-items: center;
    font-size: 13px;
}

.admin-table-title {
    background-color: #f5f5f5;
}

.admin-user-report-item-title {
    font-weight: bold;
}

.admin-user-report-item-status {
    padding: 5px;
    min-width: 2em;
    white-space: nowrap;
    text-align: center;
    align-items: center;
    font-size: 13px;
}

.admin-user-report-none {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    font-size: 13px;
}

.admin-user-report-item-status-processing {
    background-color: #feacac;
    border-radius: 10px;
}

.modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 500px;
    /* 모달 창의 최대 너비 */
    z-index: 100;
    overflow: auto;
}

.modal-content {
    position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        border-radius: 10px;
        width: 100%;
        max-width: 500px;
        /* 모달 창의 최대 너비 */
}

.modal-header {
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.modal-body {
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
}

.modal-footer {
    padding: 10px;
    border-top: 1px solid #ddd;
    text-align: center;
}

.modal-footer button {
    margin: 5px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
}

.modal-footer button:hover {
    background-color: #ddd;
}

/* @media screen and (max-width: 768px) {

    .modal-wrapper {
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100%;
    }

    .modal-content {
        border-radius: 0;
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        overflow: auto;
    }
} */