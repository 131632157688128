* {
  margin: 0;
  padding: 0;
}

.wrapper {
  margin: 0 auto;
  padding: 10px;
}

.selectBox {
  display: inline-block;
  margin: 7% 6% 2% 6%;
  padding: 5px;
  border: 1px solid #e0e0e0;
  color: #2c2c2c;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  width: 130px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0px 2px 5px #b0b0b050;
}

.selectBox:focus {
  background-color: #e0e0e0;
  cursor: pointer;
  color: #2c2c2c;
  transform: all ease 2s 0s;
}

.boardTitle {
  font-size: 18px;
  font-weight: 600;
}

.boardTitle_select {
  font-size: 16px;
  text-align: center;
}

.RegionSelectBtns {
  margin: 3% 0 1% 62%;
}

.RegionSelectBox {
  margin: 3%;
  padding: 6px 12px;
  color: #fff;
  background-color: #537fe7;
  border-radius: 5px;
}

.boardExplain {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 100;
}

.totalBoardTitle {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 10px 15px 0 20px;
  color: #537fe7;
}

/* RegionBoardList.js */
.div_boardwrite_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 30px auto;
}

.boardwrite_btn {
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
