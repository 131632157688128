.regionmodal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  /* overflow: scroll; */
}

.regionModal::-webkit-scrollbar {
  display: none;
}

.regionModal > .regionModalContainer {
  width: 90%;
  max-width: 350px;
  max-height: 480px;
  margin: 10px auto;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  /* overflow: scroll; */
}

.regionModalContainer::-webkit-scrollbar {
  display: none;
}

.regionModal > .regionModalContainer > .regionModalHeader {
  position: relative;
  margin-bottom: 10px;
  padding: 16px 50px 16px 16px;
  border-top: 2px solid #d3e0ff;
  border-bottom: 2px solid #d3e0ff;
  font-weight: 700;
  text-align: center;
}

.regionModal > .regionModalContainer > .regionModalHeader .regionModalBtn1 {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border: 0;
}

.regionModalBtn1:hover {
  opacity: 0.5;
}

.regionModal > .regionModalContainer > .regionModalMain > .regionModalList {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.regionmodal > .regionModalContainer > .regionModalMain {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}
.regionmodal > .regionModalContainer > .regionModalFooter {
  padding: 12px 16px;
  text-align: right;
  background-color: AliceBlue;
}

.regionModalBtn2 {
  display: flex;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 50px;
  padding: 6px 12px;
  color: #fff;
  background-color: #537fe7;
  border-radius: 5px;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  border: 0;
}

.regionModalBtn2:hover {
  opacity: 0.5;
}

.regionmodal.openRegion {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.regionModalList li {
  /* list-style: none; */
  text-align: center;
  list-style: none;
  margin: 1.5% 3%;
  width: 100px;
  padding: 5px;
  background-color: #e8eff2;
  border-radius: 0.8rem;
  cursor: pointer;
}

.regionModalList li:hover {
  opacity: 0.5;
}
